import { HeaderButton } from "../../../ui/Button";
import "./style.scss";
import React from "react";

interface IMainAuthButton {
  onClick: () => void;
}

export default function MainAuthButton({ onClick }: IMainAuthButton) {
  return <HeaderButton onClick={onClick}>Connect Wallet</HeaderButton>;
}
