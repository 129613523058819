import App from "./App";
import { Web3ModalProvider } from "./components/providers/Web3ModalProvider";
import "./index.scss";
import "./patch-local-storage-for-github-pages";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Web3ModalProvider>
    <RecoilRoot>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </RecoilRoot>
  </Web3ModalProvider>
);
