import {
  findAllNftsByAddress,
  getAllFootballerPartsBySkin,
  isValidExportQueue,
  nftModify,
  nftOwned,
  saveToExportQueue,
} from "../../config/requests";
import {
  CONTRACT_CREATOR,
  MINTER_ADDRESS,
  request,
} from "../../config/variables";
import { connector } from "../../connector";
import { useTonWallet } from "../../hooks/useTonWallet";
import {
  ExportQueue,
  Footballer,
  FootballerPart,
  FootballerResponse,
  ImageLinks,
} from "../../models/model";
import {
  bootsImageLinks,
  headImageLinks,
  pantsImageLinks,
  skins,
  tShirtImageLinks,
} from "../../state/mock";
import { scrollTop } from "../../utils";
import { Image } from "../templates/templates";
import { Button } from "../ui/Button";
import CustomizedCharacter from "./character/CustomizedCharacter";
import { Chooser } from "./chooser";
import { Parts } from "./parts";
import { CustomizationSlider } from "./slider";
import { notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import "swiper/scss/navigation";
import "swiper/scss/scrollbar";
import { toNano } from "ton";
import TonWeb from "tonweb";
import { bytesToHex, parseEther, stringToBytes } from "viem";
import {
  useAccount,
  useEstimateGas,
  useSendTransaction,
  useWaitForTransactionReceipt,
} from "wagmi";

interface CustomizationProps {
  theme: string;
  changeColor: (color?: string) => void;
  username: string;
  updateProfile: () => void;
}

export const Customization = ({
  theme,
  changeColor,
  username,
  updateProfile,
}: CustomizationProps) => {
  const skin: any = skins[theme as keyof Object];
  const [currentFootballerPart, setCurrentFootballerPart] =
    useState<FootballerPart>();
  const [index, setIndex] = useState<string>();
  const [footballerPartLinks, setFootballerPartLinks] = useState({
    heads: headImageLinks,
    tShirts: tShirtImageLinks,
    boots: bootsImageLinks,
    shorts: pantsImageLinks,
  });
  const [footballer, setFootballer] = useState<FootballerPart>();
  const [activePart, setActivePart] = useState("heads");
  const [activeChooser, setActiveChooser] = useState(false);
  const [colorChooser, setColorChooser] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [footballers, setFootballers] = useState<FootballerPart[]>([]);
  const tonWallet = useTonWallet();
  const { isConnected, address: addressEth } = useAccount();
  const { sendTransactionAsync, data: hash } = useSendTransaction();
  const { data, isPending, isSuccess } = useWaitForTransactionReceipt({
    hash: hash,
  });
  const estimateGas = useEstimateGas();

  useEffect(() => {
    if (!isPending && data && isSuccess) {
      if (data.status === "success") {
        console.log("Transaction succeeded");
        saveToQueueEth();
        getAllNfts("ETH");
      } else {
        console.log("Transaction failed");
      }
    }
  }, [isPending, data, isSuccess]);

  useEffect(() => {
    if (isConnected) {
      getAllNfts("ETH");
    } else if (tonWallet !== undefined) {
      getAllNfts("TON");
    } else if (username !== undefined) {
      getAllNftOwned();
    }
  }, [isConnected, addressEth, tonWallet, username]);

  useEffect(() => {
    const processAllFootballerPartsBySkin = async () => {
      try {
        const result: FootballerPart[] = await getAllFootballerPartsBySkin(
          theme
        );

        const uniquePaths = (
          parts: FootballerPart[],
          path: (part: FootballerPart) => string
        ) => [...new Set(parts.map(path))];

        setFootballerPartLinks((prev) => ({
          ...prev,
          heads: uniquePaths(result, (part) => `/heads/${part.headType}.png`),
          boots: uniquePaths(
            result,
            (part) => `/clothes/boots/${part.bootColor?.split("|")[0]}.png`
          ),
          shorts: uniquePaths(
            result,
            (part) => `/clothes/shorts/${part.shortColor}.png`
          ),
          tShirts: uniquePaths(
            result,
            (part) => `/clothes/tShirts/${part.tShirtColor}.png`
          ),
        }));
      } catch (error) {
        console.error("Ошибка при обработке частей футболистов:", error);
      }
    };

    processAllFootballerPartsBySkin();

    setCurrentFootballerPart({
      bootColor: skin.legs,
      tShirtColor: skin.body,
      headType: skin.head,
      shortColor: skin.pants,
      skinColor: theme,
    });
  }, [skin, theme]);

  useEffect(() => {
    if (footballer) {
      setCurrentFootballerPart((prev) => ({
        ...prev,
        bootColor: `${request.pathToBig}/clothes/boots/${
          footballer.bootColor?.split("|")[0]
        }.png`,
        tShirtColor: `${request.pathToBig}/clothes/tShirts/${footballer.tShirtColor}.png`,
        headType: `${request.pathToBig}/heads/${footballer.headType}.png`,
        shortColor: `${request.pathToBig}/clothes/shorts/${footballer.shortColor}.png`,
        skinColor: theme,
      }));
    }
  }, [footballer]);

  const checkFootballerMatched = () => {
    const {
      headType: currentHeadType,
      tShirtColor: currentTShirtColor,
      shortColor: currentShortColor,
      bootColor: currentBootColor,
    } = currentFootballerPart || {};

    const {
      head: skinHead,
      body: skinBody,
      pants: skinPants,
      legs: skinLegs,
    } = skin;

    const isHeadMatched = currentHeadType?.includes(skinHead);
    const isTShirtMatched = currentTShirtColor?.includes(skinBody);
    const isShortsMatched = currentShortColor?.includes(skinPants);
    const isBootsMatched = currentBootColor?.includes(skinLegs);

    return {
      isFootballerMatched:
        isHeadMatched || isTShirtMatched || isShortsMatched || isBootsMatched,
    };
  };

  const getFootballer = () => {
    const extractFileName = (path?: string) =>
      path?.substring(path?.lastIndexOf("/") + 1)?.replace(".png", "");
    const { bootColor, headType, shortColor, skinColor, tShirtColor } =
      currentFootballerPart || {};

    return {
      nftIndex: index,
      bootColor: extractFileName(bootColor),
      headType: extractFileName(headType),
      shortColor: extractFileName(shortColor),
      skinColor: extractFileName(skinColor),
      tShirtColor: extractFileName(tShirtColor),
    };
  };

  const getExportQueueByFootballerAndIndex = (
    footballer: Footballer,
    index: number,
    blockchain: "TON" | "ETH"
  ): ExportQueue => {
    return {
      footballerPart: footballer,
      nftIndex: index,
      skinColor: footballer.skinColor,
      operation: "updateClothes",
      addressId: tonWallet ? address : addressEth,
      blockchain: blockchain,
    };
  };

  const getPayload = async (footballer: Footballer) => {
    const parts = [
      index,
      footballer.headType?.replace("head_", ""),
      footballer.tShirtColor?.replace("tShirt_", ""),
      footballer.shortColor?.replace("shorts_", ""),
      footballer.bootColor?.replace("boots_", ""),
    ];

    const payloadString = parts.join(";");
    const cell = new TonWeb.boc.Cell();
    cell.bits.writeUint(0, 32);
    cell.bits.writeString(payloadString);
    const bytes = await cell.toBoc();
    return TonWeb.utils.bytesToBase64(bytes);
  };

  const getPayloadEth = (footballer: Footballer) => {
    const parts = [
      index,
      footballer.headType?.replace("head_", ""),
      footballer.tShirtColor?.replace("tShirt_", ""),
      footballer.shortColor?.replace("shorts_", ""),
      footballer.bootColor?.replace("boots_", ""),
    ];

    return parts.join(";");
  };

  const isButtonDisabled = () => {
    if (tonWallet || username || isConnected) {
      return footballers.length === 0 || footballer || colorChooser !== theme;
    }

    return false;
  };

  const showNotification = (
    type: "info" | "success" | "error" | "warning",
    message: string,
    description?: string
  ) => {
    notification[type]({
      message,
      description,
      duration: 10,
    });
  };

  const checkAndUpdateFootballer = async (exportQueueType: "TON" | "ETH") => {
    const { isFootballerMatched } = checkFootballerMatched();

    if (
      !isFootballerMatched &&
      index !== undefined &&
      !footballer &&
      colorChooser === theme
    ) {
      const footballer = getFootballer();
      const payload =
        exportQueueType === "TON"
          ? await getPayload(footballer)
          : getPayloadEth(footballer);
      const exportQueue = getExportQueueByFootballerAndIndex(
        footballer,
        +index,
        exportQueueType
      );
      const isValid = await isValidExportQueue(exportQueue);

      if (!isValid.ok) {
        showNotification(
          "error",
          (await isValid.json()).message ?? "Footballer already updated!"
        );
        return;
      }

      showNotification("info", "Please, confirm operation in your wallet");

      return { footballer, payload, exportQueue };
    } else if (isFootballerMatched && colorChooser === theme) {
      showNotification("warning", "Finish dressing the character");
    }
    return null;
  };

  const saveToQueueEth = async () => {
    const updateData = await checkAndUpdateFootballer("ETH");
    if (updateData) {
      const { exportQueue } = updateData;

      try {
        const response = await saveToExportQueue(exportQueue);

        if (response.ok) {
          showNotification(
            "success",
            "Successful transaction",
            "You successfully updated footballer"
          );
        } else {
          showNotification(
            "error",
            "Error during updating",
            (await response.json()).message
          );
        }
      } catch (e: any) {
        showNotification("error", "Sending transaction error", e.message);
      }
    }
  };

  const handleUpdateClothesWeb3 = useCallback(async () => {
    const updateData = await checkAndUpdateFootballer("TON");
    if (updateData) {
      const { payload, exportQueue } = updateData;
      const transactionData = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: MINTER_ADDRESS,
            amount: toNano("0.01"),
            payload: payload,
          },
        ],
      };

      const responseTransaction = await connector.sendTransaction(
        transactionData
      );
      console.log(responseTransaction);

      showNotification("info", "Waiting for confirm your operation...");

      const response = await saveToExportQueue(exportQueue);

      if (response.ok) {
        showNotification(
          "success",
          "Successful transaction",
          "You successfully updated footballer"
        );
      } else {
        showNotification(
          "error",
          "Error during updating",
          (await response.json()).message
        );
      }
    }
  }, [index, currentFootballerPart]);

  const handleUpdateClothesEth = async () => {
    const updateData = await checkAndUpdateFootballer("ETH");
    if (updateData) {
      const { payload } = updateData;

      try {
        await sendTransactionAsync({
          to: `0x${CONTRACT_CREATOR}`,
          value: parseEther("0"),
          data: bytesToHex(stringToBytes(payload)),
          gas: estimateGas.data,
        });

        showNotification("info", "Waiting for confirm your operation...");
      } catch (e: any) {
        showNotification("error", "Sending transaction error", e.message);
      }
    }
  };

  const handleUpdateClothesWeb2 = async () => {
    const { isFootballerMatched } = checkFootballerMatched();

    if (
      !isFootballerMatched &&
      index !== undefined &&
      !footballer &&
      colorChooser === theme
    ) {
      const footballer = getFootballer();
      try {
        const response = await nftModify(footballer);

        if (response.ok) {
          showNotification(
            "success",
            "Successful transaction",
            "You successfully updated footballer"
          );
          updateProfile();
        } else {
          showNotification(
            "error",
            "Error during updating",
            (await response.json()).detail
          );
        }
      } catch (e) {
        showNotification(
          "error",
          "Error during updating",
          "Internal server error"
        );
      }
    } else if (isFootballerMatched && colorChooser === theme) {
      showNotification("warning", "Finish dressing the character");
    }
  };

  const handleUpdate = async () => {
    if (isButtonDisabled()) {
      scrollTop();
      return;
    }

    if (tonWallet) {
      handleUpdateClothesWeb3();
    } else if (username) {
      await handleUpdateClothesWeb2();
      getAllNftOwned();
    } else if (isConnected) {
      await handleUpdateClothesEth();
    } else {
      scrollTop();
    }
  };

  const handleAction = (footballer: FootballerPart) => {
    setColorChooser(footballer.skinColor);
    setIndex(footballer.number);
    if (footballer.playerType === "bag") {
      setActiveChooser(true);
      setFootballer(undefined);
    } else {
      setFootballer(footballer);
    }
    changeColor(footballer.skinColor);
  };

  const getAllNftOwned = useCallback(async () => {
    const nfts: FootballerPart[] = (await (await nftOwned()).json()).map(
      (nft: FootballerResponse) => {
        return {
          headType: nft.footballerPart?.headType,
          bootColor: nft.footballerPart?.bootColor,
          shortColor: nft.footballerPart?.shortColor,
          tShirtColor: nft.footballerPart?.tShirtColor,
          skinColor: nft.skinColor,
          image: nft.image,
          playerType: nft.footballerPartId === null ? "bag" : "not-bag",
          number: nft.nftIndex,
          email: nft.email,
        };
      }
    );
    setFootballers(nfts);
  }, []);

  const getAllNfts = async (blockchain: "TON" | "ETH") => {
    const address = addressEth ?? tonWallet?.account.address;
    if (!address) return;
    setAddress(address);
    const params = new URLSearchParams();
    params.append("blockchain", blockchain);
    const nfts: FootballerPart[] = (
      await (await findAllNftsByAddress(params, address)).json()
    ).map((nft: FootballerResponse) => {
      return {
        headType: nft.footballerPart?.headType,
        bootColor: nft.footballerPart?.bootColor,
        shortColor: nft.footballerPart?.shortColor,
        tShirtColor: nft.footballerPart?.tShirtColor,
        skinColor: nft.skinColor,
        image: nft.image,
        playerType: nft.footballerPartId === null ? "bag" : "not-bag",
        number: nft.nftIndex,
        email: nft.email,
      };
    });
    setFootballers(nfts);
  };

  const getImageLinks = (): ImageLinks => {
    return {
      heads: footballerPartLinks.heads,
      tShirts: footballerPartLinks.tShirts,
      shorts: footballerPartLinks.shorts,
      boots: footballerPartLinks.boots,
    };
  };

  const handleCurrentPartUpdate = (part: keyof ImageLinks, link: string) => {
    const updateField = {
      heads: { headType: request.pathToBig + link },
      tShirts: { tShirtColor: request.pathToBig + link },
      shorts: { shortColor: request.pathToBig + link },
      boots: { bootColor: request.pathToBig + link },
    }[part];

    setCurrentFootballerPart((prev) => ({
      ...prev,
      ...updateField,
    }));
  };
  return (
    <section className="custom" id="customization">
      <div className="custom__title">Wear him, customize ✦️ Sell or Play!</div>
      <div className="custom__inner">
        <div className="custom__controller">
          <Parts action={setActivePart} activePart={activePart} />
          <div className="custom__content">
            {(
              ["heads", "tShirts", "shorts", "boots"] as (keyof ImageLinks)[]
            ).map((part) => (
              <div
                key={part}
                className={
                  activePart === part
                    ? "custom__content-part visible"
                    : "custom__content-part"
                }
              >
                <CustomizationSlider
                  imageLinks={getImageLinks()[part as keyof ImageLinks]}
                  action={(link) => handleCurrentPartUpdate(part, link)}
                  className={part}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="custom__character">
          <div className="custom__subject">
            <CustomizedCharacter
              head={currentFootballerPart?.headType || skin.head}
              rightShoulder={skin.rightShoulder}
              leftShoulder={skin.leftShoulder}
              tShirt={currentFootballerPart?.tShirtColor || skin.body}
              rightHand={skin.rightHand}
              leftHand={skin.leftHand}
              pants={currentFootballerPart?.shortColor || skin.pants}
              legs={skin.legs}
              boots={currentFootballerPart?.bootColor || skin.legs}
            />

            <div className="custom__platform">
              <Image src="/customization/platform.svg" alt="platform" />
            </div>
          </div>

          {(tonWallet || username || isConnected) && (
            <Chooser
              footballers={footballers}
              color={theme}
              action={handleAction}
            />
          )}
        </div>
      </div>
      <div className="custom__buttons">
        <Button
          className={`update-button ${isButtonDisabled() ? "disabled" : ""}`}
          onClick={handleUpdate}
        >
          Update Character
        </Button>
      </div>
      {!activeChooser || colorChooser !== theme ? (
        <div className="update-description">First buy a character</div>
      ) : null}
    </section>
  );
};
