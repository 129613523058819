import EthMintButton from "../Mint/EthMintButton";
import TonMintButton from "../Mint/TonMintButton";
import Web2BuyButton from "../Mint/Web2BuyButton";
import "./style.scss";
import { useCallback } from "react";
import { useTonWallet } from "src/hooks/useTonWallet";
import { useAccount } from "wagmi";

interface ITxForm {
  color: string;
  username: string;
}

export function TxForm({ color, username }: ITxForm) {
  const wallet = useTonWallet();
  const { isConnected } = useAccount();

  const BuyButton = useCallback(() => {
    if (wallet) {
      return <TonMintButton color={color} />;
    } else if (username) {
      return <Web2BuyButton color={color} username={username} />;
    } else if (isConnected) {
      return <EthMintButton color={color} />;
    }
  }, [wallet, username, isConnected, color]);

  return (
    <div className="buy-buttons" style={{ textAlign: "center" }}>
      <BuyButton />
    </div>
  );
}
