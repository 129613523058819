import {
  MINTER_ADDRESS,
  TON_CENTER_API_KEY,
  TON_CENTER_HOST,
} from "../../config/variables";
import { connector, sendTransaction } from "../../connector";
import { useTonWallet } from "../../hooks/useTonWallet";
import { ExportQueue, Footballer } from "../../models/model";
import { generatePayload } from "../../nft-transaction";
import { walletsListQuery } from "../../state/wallets-list";
import { ButtonMint } from "../TxForm/ButtonMint";
import { ScMint } from "../TxForm/mintyle";
import { SendTransactionRequest } from "@tonconnect/sdk";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import { Address } from "ton";
import TonWeb from "tonweb";

const tonWeb = new TonWeb(
  new TonWeb.HttpProvider(TON_CENTER_HOST, {
    apiKey: TON_CENTER_API_KEY,
  })
);

interface ITonMintButton {
  color: string;
}

export default function TonMintButton({ color }: ITonMintButton) {
  const [price, setPrice] = useState<string>("450000000");
  const [tx, setTx] = useState<SendTransactionRequest | null>(null);
  const [sendTo, setSendTo] = useState<string>("");
  const wallet = useTonWallet();
  const walletsList = useRecoilValueLoadable(walletsListQuery);

  const removeTxMessage = useCallback(() => {
    if (tx && tx.messages.length === 2) {
      setTx(
        (value) =>
          ({
            ...value,
            messages: value!.messages.slice(1),
          } as SendTransactionRequest)
      );
    }
  }, [tx]);

  const handleMint = async () => {
    if (!tx) return;

    const footballer = new Footballer();
    footballer.skinColor = color;

    const queue = new ExportQueue();
    queue.skinColor = footballer.skinColor;
    queue.addressId = connector.wallet!.account.address;
    queue.operation = "mint";
    queue.blockchain = 'TON'

    await sendTransaction(tx, walletsList.contents.walletsList[0], queue);
  };

  useEffect(() => {
    if (wallet) {
      const walletAddr = new TonWeb.utils.Address(
        connector.wallet!.account.address
      );
      const minterAddr = new TonWeb.utils.Address(MINTER_ADDRESS);

      const fetchData = async () => {
        const userAddrCell = new tonWeb.boc.Cell();
        userAddrCell.bits.writeAddress(walletAddr);
        const userAddrSlice = tonWeb.utils.bytesToBase64(
          await userAddrCell.toBoc(false)
        );
        const priceFor = await tonWeb.provider.call(
          minterAddr.toString(),
          "get_price_for",
          [["tvm.Slice", userAddrSlice]]
        );
        const priceForInt = parseInt(priceFor.stack[0][1], 16);

        setPrice(String(priceForInt));

        const tx = {
          validUntil: Date.now() + 1000000,
          messages: [
            {
              address: MINTER_ADDRESS,
              amount: String(priceForInt),
            },
          ],
        };
        setTx(tx);
      };
      fetchData();
    } else {
      setTx(null);
      setSendTo("");
    }
  }, [wallet]);

  useEffect(() => {
    if (sendTo) {
      try {
        Address.parseFriendly(sendTo);
      } catch (e) {
        removeTxMessage();
        return;
      }

      const payload = generatePayload(sendTo);

      setTx(
        (value) =>
          ({
            ...value,
            messages: [...value!.messages].concat({
              address: value!.messages[0].address,
              amount: "18000000000",
              payload,
            }),
          } as SendTransactionRequest)
      );
    } else {
      removeTxMessage();
    }
  }, [sendTo]);
  return (
    tx && (
      <ScMint>
        <div className="mint">
          <div className="mint-description">Slide to select a character</div>
          <ButtonMint className="mint__button" onClick={handleMint}>
            Buy {tonWeb.utils.fromNano(price)} TON
          </ButtonMint>
        </div>
      </ScMint>
    )
  );
}
