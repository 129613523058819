import "./app.scss";
import Header from "./components/Header/Header";
import IntroDescription from "./components/IntroDescription/IntroDescription";
import { userInfo } from "./config/requests";
import { Web2User } from "./models/model";
import { notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Contacts } from "src/components/contacts";
import { Slider } from "src/components/slider";
import { connector } from "src/connector";
import { useAccount } from "wagmi";

export default function App() {
  const [user, setUser] = useState<Web2User>();
  const location = useLocation();
  const navigate = useNavigate();
  const { isConnected, isConnecting } = useAccount();

  const getConnection = useCallback(async () => {
    const response = await userInfo();
    console.log(!isConnected)
    if (response.status != 401) {
      const json = await response.json();
      setUser(json["user"]);
    } else if (!isConnected) {
      console.log('ton')
      setUser(undefined);
      await connector.restoreConnection();
      console.log("Connection restored");
    }
  }, [isConnecting, isConnected]);

  useEffect(() => {
    getConnection();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const successParam = searchParams.get("success");

    if (successParam === "true") {
      notification.success({
        message: "Successful purchase",
        description:
          "Please wait a couple of minutes and your purchase will be displayed",
        duration: 10,
      });
      navigate("/");
    }
  }, [location.search]);

  return (
    <>
      <Header
        username={user?.username.split("@")[0]}
        updateProfile={getConnection}
      />
      <main>
        <IntroDescription />
        <Slider
          username={user?.username.split("@")[0]}
          updateProfile={getConnection}
        />
      </main>
      <Contacts />
    </>
  );
}
