import TonIcon from "../../../../../assets/modal/ton.svg";
import { addReturnStrategy, connector } from "../../../../../connector";
import { useTonWallet } from "../../../../../hooks/useTonWallet";
import { useTonWalletConnectionError } from "../../../../../hooks/useTonWalletConnectionError";
import { walletsListQuery } from "../../../../../state/wallets-list";
import { isDesktop, isMobile, openLink } from "../../../../../utils";
import MainMenuButton from "../../MainMenuButton/MainMenuButton";
import { Modal, notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useRecoilValueLoadable } from "recoil";

interface ITonConnectButton {
  mainModalClose: () => void;
}

export default function TonConnectButton({
  mainModalClose,
}: ITonConnectButton) {
  const [modalUniversalLink, setModalUniversalLink] = useState("");
  const wallet = useTonWallet();
  const walletsList = useRecoilValueLoadable(walletsListQuery);

  const handleConnectError = useCallback(() => {
    setModalUniversalLink("");
    notification.error({
      message: "Connection was rejected",
      description: "Please approve connection to the dApp in your wallet.",
    });
  }, []);

  useTonWalletConnectionError(handleConnectError);

  useEffect(() => {
    if (connector.connected) mainModalClose();
  }, [connector.connected]);

  useEffect(() => {
    if (modalUniversalLink && wallet) {
      setModalUniversalLink("");
    }
  }, [modalUniversalLink, wallet]);

  const handleButtonClick = useCallback(async () => {
    if (!(walletsList.state === "hasValue")) {
      setTimeout(handleButtonClick, 200);
    }

    const tonkeeperConnectionSource = {
      universalLink: walletsList.contents.walletsList[0].universalLink,
      bridgeUrl: walletsList.contents.walletsList[0].bridgeUrl,
    };

    const universalLink = connector.connect(tonkeeperConnectionSource);

    if (!isDesktop() && walletsList.contents.embeddedWallet) {
      connector.connect({
        jsBridgeKey: walletsList.contents.embeddedWallet.jsBridgeKey,
      });
    } else if (isMobile()) {
      openLink(addReturnStrategy(universalLink, "none"), "_blank");
    } else {
      setModalUniversalLink(universalLink);
    }
  }, [walletsList]);

  return (
    <>
      <MainMenuButton src={TonIcon} title="TON" action={handleButtonClick} />
      <Modal
        title="Connect to Tonkeeper"
        open={!!modalUniversalLink}
        onOk={() => setModalUniversalLink("")}
        onCancel={() => setModalUniversalLink("")}
      >
        <QRCode
          size={256}
          style={{ height: "260px", maxWidth: "100%", width: "100%" }}
          value={modalUniversalLink}
          viewBox={`0 0 256 256`}
        />
      </Modal>
    </>
  );
}
