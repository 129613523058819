export function isMobile(): boolean {
  return window.innerWidth <= 500;
}

export function isDesktop(): boolean {
  return window.innerWidth >= 1050;
}

export function openLink(href: string, target = "_self") {
  window.open(href, target, "noreferrer noopener");
}

export const scrollTop = () =>
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
