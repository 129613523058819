import { ExportQueue, ILogin } from "../models/model";
import {
  addToExportQueue,
  findAllFootballerPartsBySkin,
  findAllFootballerStatesInList,
  findAllNftsByAddressId,
  findSkinsAndCount,
  getNftOwned,
  getPayLink,
  getUserInfo,
  googleOauth,
  logout,
  nftUpdate,
  signIn,
  signUp,
  validateBySkin,
  validateExportQueue,
} from "./endpoints";

//WEB2
export const loginGoogle = async () => await fetch(googleOauth);

export const registration = async (data: ILogin) =>
  await fetch(`${signUp}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

export const login = async (data: ILogin) =>
  await fetch(`${signIn}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      ...data,
    }),
    credentials: "include",
  });

export const disconnect = async () =>
  await fetch(`${logout}`, {
    method: "POST",
    credentials: "include",
  });

export const generateLink = async (color: string) =>
  await (
    await fetch(`${getPayLink}?color=${color}`, { credentials: "include" })
  ).json();

export const nftOwned = async () =>
  await fetch(`${getNftOwned}`, { credentials: "include" });

export const findAllNftsByAddress = async (
  params: URLSearchParams,
  addressId: string
) => await fetch(`${findAllNftsByAddressId(addressId)}?${params.toString()}`);

export const userInfo = async () =>
  await fetch(getUserInfo, { credentials: "include" });

export const nftModify = async (data: any) =>
  await fetch(`${nftUpdate}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(data),
  });

//WEB3
export const getSkinsAndCount = async (params: URLSearchParams) =>
  await (await fetch(`${findSkinsAndCount}?${params.toString()}`)).json();
export const getAllFootballerStatesInList = async (nftItems: any) =>
  await (
    await fetch(`${findAllFootballerStatesInList}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ nftItems }),
    })
  ).json();

export const getAllFootballerPartsBySkin = async (theme: string) =>
  await (await fetch(`${findAllFootballerPartsBySkin}/${theme}`)).json();

export const isValidSkinColor = async (
  params: URLSearchParams,
  skinColor?: string
) => await fetch(`${validateBySkin(skinColor)}?${params.toString()}`);

export const isValidExportQueue = async (exportQueue: ExportQueue) => {
  return await fetch(validateExportQueue, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(exportQueue),
  });
};

export const saveToExportQueue = async (exportQueue: ExportQueue) => {
  return await fetch(addToExportQueue, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(exportQueue),
  });
};
