import { isValidSkinColor, saveToExportQueue } from "./config/requests";
import { NotValidError } from "./errors/error";
import { ExportQueue } from "./models/model";
import {
  CHAIN,
  SendTransactionRequest,
  TonConnect,
  UserRejectsError,
  WalletInfo,
  WalletInfoInjectable,
} from "@tonconnect/sdk";
import { notification } from "antd";
import { isMobile, openLink } from "src/utils";
import { Address } from "ton";
import {BLOCKCHAIN_QUERY_PARAM} from "./config/variables";

export const connector = new TonConnect({
  manifestUrl: "https://mint.shaolin.soccer/tonconnect-manifest.json",
});

export async function sendTransaction(
  tx: SendTransactionRequest,
  wallet: WalletInfo,
  queue: ExportQueue
) {
  try {
    if (
      "universalLink" in wallet &&
      !(wallet as WalletInfoInjectable).embedded &&
      isMobile()
    ) {
      openLink(addReturnStrategy(wallet.universalLink, "none"), "_blank");
    }
    const params = new URLSearchParams();
    params.append(BLOCKCHAIN_QUERY_PARAM, "TON");
    const isValid = await isValidSkinColor(params, queue.skinColor);

    if (!isValid.ok) {
      throw new NotValidError("Could not mint this color");
    }

    notification.info({
      message: "Please, confirm operation in your wallet",
      duration: 10,
    });

    const result = await connector.sendTransaction(tx);
    const response = await saveToExportQueue(queue);

    console.log(response);

    const mintFor = Address.parseRaw(
      connector.wallet!.account.address
    ).toFriendly({
      testOnly: connector.wallet!.account.chain === CHAIN.TESTNET,
    });
    console.log("mintFor: ", mintFor);

    notification.success({
      message: "Successful transaction",
      description:
        "You transaction was successfully sent. Please wait until the transaction is included to the TON blockchain. Player NFT minted to your wallet.",
      duration: 10,
    });

    console.log(`Send tx result: ${JSON.stringify(result)}`);
    return result;
  } catch (e) {
    let message = "Send transaction error";
    let description = "";

    if (typeof e === "object" && e instanceof UserRejectsError) {
      message = "You rejected the transaction";
      description = "Please try again and confirm transaction in your wallet.";
    }

    if (typeof e === "object" && e instanceof NotValidError) {
      message = "Color is not available";
      description = "Please try again and choose another color.";
    }

    notification.error({
      message,
      description,
    });
    console.log(e);
    throw e;
  }
}

export function addReturnStrategy(
  url: string,
  returnStrategy: "back" | "none"
): string {
  const link = new URL(url);
  link.searchParams.append("ret", returnStrategy);
  return link.toString();
}
