import { connector } from "../../../../../connector";
import { useSlicedAddress } from "../../../../../hooks/useSlicedAddress";
import { useTonWallet } from "../../../../../hooks/useTonWallet";
import { HeaderButton } from "../../../../ui/Button";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import React from "react";

export default function TonDisconnectButton() {
  const wallet = useTonWallet();
  const account = wallet?.account;
  const address = useSlicedAddress(account?.address, account?.chain);

  const menu = {
    onClick: () => connector.disconnect(),
    items: [
      {
        label: "Disconnect",
        key: "1",
      },
    ],
  };

  return (
    <Dropdown className="header-dropdown" menu={menu}>
      <HeaderButton>
        <Space color="#FDFFFD">
          {address}
          <DownOutlined />
        </Space>
      </HeaderButton>
    </Dropdown>
  );
}
