// SHAOLIN SOCCER BE
import { NFT_COLLECTION_ADDRESS } from "./variables";


const HOST = process.env.REACT_APP_MINT_SERVER ?? 'https://mintton-testnet-backend.shaolin.soccer';
// const HOST = "http://localhost:8099";

//WEB2
export const googleOauth = `${HOST}/web2/oauth/`;
export const signUp = `${HOST}/web2/auth/sign_up`;
export const signIn = `${HOST}/web2/auth/`;
export const logout = `${HOST}/web2/auth/logout`;
export const nftUpdate = `${HOST}/web2/nftModify`;
export const getUserInfo = `${HOST}/web2/userInfo`;
export const getNftOwned = `${HOST}/web2/nftOwned`;
export const getPayLink = `${HOST}/web2/generateLink`;

//WEB3
export const findSkinsAndCount = `${HOST}/footballers/skins`;
export const findAllFootballerPartsBySkin = `${HOST}/footballers/skins`;
export const findAllFootballerStatesInList = `${HOST}/footballers/state`;
export const validateBySkin = (skinColor?: string) =>
  `${HOST}/footballers/state/validate/${skinColor}`;
export const validateExportQueue = `${HOST}/footballers/queue/validate`;
export const addToExportQueue = `${HOST}/footballers/queue`;

//ETH
export const findAllNftsByAddressId = (addressId: string) =>
  `${HOST}/footballers/state/${addressId}`;

// TON API
const TON_API_HOST = process.env.REACT_APP_TON_API ?? "https://testnet.tonapi.io";
export const findAllNftByAddress = (address: string) =>
  `${TON_API_HOST}/v2/accounts/${address}/nfts?collection=${NFT_COLLECTION_ADDRESS}`;
